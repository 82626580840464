import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import IframeResizer from 'iframe-resizer-react'

import { _ } from 'Services/I18n'

import { Container, Dash, StyledTitle } from './styles'

const Widget = forwardRef(({ widgetUrl, ...rest }, ref) => {
  return (
    <Container ref={ref} {...rest}>
      <StyledTitle display={['none', 'none', 'none', 'block']}>
        {_('common.reviews')}
      </StyledTitle>
      <Dash display={['none', 'none', 'none', 'block']} />
      <IframeResizer
        className="reviews-widget"
        frameBorder="0"
        minHeight={500}
        src={widgetUrl}
        title="Reviews Widget"
      />
    </Container>
  )
})

Widget.displayName = 'Widget'

Widget.defaultProps = {
  widgetUrl: null,
}

Widget.propTypes = {
  widgetUrl: PropTypes.string,
}

export default Widget
