import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import size from 'lodash/size'

import { _ } from 'Services/I18n'

import { Flex } from 'Components/UI'

import {
  Container,
  Dash,
  StyledTitle,
  Content,
  Col,
  SubTitle,
  Text,
  StarIcon,
  FileIcon,
} from './styles'

const Features = ({ color, features, licences }) => {
  if (!size(features) && !size(licences)) {
    return null
  }

  return (
    <Container>
      <StyledTitle>{_('common.features')}</StyledTitle>
      <Dash />
      <Content>
        {size(features) > 0 && (
          <Col>
            <SubTitle mb="11px">
              <StarIcon color={color} />
              {_('common.restaurantFeatures')}
            </SubTitle>
            {map(features, feature => (
              <Flex key={feature}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: feature,
                  }}
                  top={2}
                />
              </Flex>
            ))}
          </Col>
        )}
        {size(licences) > 0 && (
          <Col>
            <SubTitle mb="11px">
              <FileIcon color={color} />
              {_('common.licence')}
            </SubTitle>
            {map(licences, licence => (
              <Flex key={licence}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: licence,
                  }}
                  top={2}
                />
              </Flex>
            ))}
          </Col>
        )}
      </Content>
    </Container>
  )
}

Features.defaultProps = {
  color: '',
}

Features.propTypes = {
  color: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  licences: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Features
