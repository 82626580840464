import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { _ } from 'Services/I18n'

import { Flex, Dot } from 'Components/UI'

import {
  Container,
  Dash,
  StyledTitle,
  AdditionalInfoContent,
  CustomFilterContainer,
  SubTitle,
  Text,
  TagIcon,
} from './styles'

const getFilters = (filters, clientFilters) => {
  return Object.keys(filters)
    .map(filterName => {
      const filterValues =
        get(filters, filterName) === null ? {} : get(filters, filterName, {})
      return {
        title: get(clientFilters, `${filterName}.label`),
        values: Object.values(filterValues),
      }
    })
    .filter(filter => !isEmpty(filter.values))
}

const AdditionalInfo = ({ baseTheme, filters, ...rest }) => {
  const color = get(baseTheme, 'colors.secondary_background')
  const filtersArray = getFilters(filters, get(baseTheme, 'custom_filters', {}))

  if (isEmpty(filtersArray)) {
    return null
  }

  return (
    <Container {...rest}>
      <StyledTitle>{_('common.additionalInformation')}</StyledTitle>
      <Dash />
      <AdditionalInfoContent>
        {map(filtersArray, (filter, filterIndex) => (
          <CustomFilterContainer key={filterIndex}>
            <SubTitle mb="11px">
              <TagIcon color={color} />
              {filter.title}
            </SubTitle>
            {map(filter.values, (value, index) => (
              <Flex alignItems="center" key={`${filterIndex}_${index}`} mt={2}>
                <Dot alignSelf="flex-start" mr={2} mt={2} />
                <Text top={0}>{value}</Text>
              </Flex>
            ))}
          </CustomFilterContainer>
        ))}
      </AdditionalInfoContent>
    </Container>
  )
}

AdditionalInfo.defaultProps = {
  filters: {},
}

AdditionalInfo.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default AdditionalInfo
