import React from 'react'
import PropTypes from 'prop-types'

import { PHONE_MASK, LOCAL_PHONE_MASK, SHORTNUM_MASK } from 'Constants/ids'

import { PhoneIcon, MaskedText } from './styles'

function getMask(phoneNumber) {
  // Six digit 13 numbers
  if (phoneNumber.length === 6) {
    return SHORTNUM_MASK
  }

  // Mobile
  if (phoneNumber.startsWith('04')) {
    return PHONE_MASK
  }

  // Landlines
  if (phoneNumber.startsWith('0')) {
    return LOCAL_PHONE_MASK
  }

  // 1800 or 1300 numbers
  return PHONE_MASK
}
const Telephone = ({ color, phone, size }) => (
  <>
    <PhoneIcon color={color} size={size} />
    <MaskedText mask={getMask} value={phone.replace(/\D/g, '')} />
  </>
)

Telephone.defaultProps = {
  color: '',
  phone: '',
  size: 20,
}

Telephone.propTypes = {
  color: PropTypes.string,
  phone: PropTypes.string,
  size: PropTypes.number,
}

export default Telephone
