import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'

import MaskedInput from 'react-text-mask'

import { mapPlaceHolderImage } from 'Assets/Images'

import {
  alarmClockGlyph,
  browserGlyph,
  likeGlyph,
  mapGlyph,
  phoneGlyph,
  starGlyph,
  fileGlyph,
  goodfoodhatGlyph,
  clockGlyph,
  tagGlyph,
  externalLinkGlyph,
} from 'Assets/Svg'

import {
  Text as BaseText,
  Flex,
  Box,
  Icon,
  Dash as DashUI,
  Button,
  Select,
} from 'Components/UI'

export const Container = styled(Flex).attrs(() => ({
  flexDirection: 'column',
}))`
  width: 100%;

  .reviews-widget {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    .reviews-widget {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }
`

export const Dash = styled(DashUI).attrs({
  mt: 2,
})``

export const StyledTitle = styled(BaseText).attrs({
  fontSize: 20,
})`
  color: ${themeGet('colors.black')};
  text-align: left;
  letter-spacing: 0.7px;
  font-weight: bold;
`

export const Content = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
})`
  flex-wrap: wrap;
`

export const Col = styled(Box).attrs({
  px: 34,
  pl: 40,
  width: 1,
})`
  flex: 1;
`

export const ThreeCol = styled(Box).attrs({
  px: 34,
  pl: 40,
  width: 1,
})`
  flex: 0 ${({ colwidth }) => colwidth}%;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    flex: 0 ${({ colwidth }) => (colwidth <= 50 ? 50 : 100)}%;
  }
`

export const Row = styled(Box).attrs({
  px: 34,
})``

export const SubTitle = styled(BaseText).attrs({
  fontSize: 2,
  mt: [24, 24, 4],
})`
  position: relative;
  font-weight: 700;
  color: #000;

  > svg {
    position: absolute;
    top: 0px;
    left: -40px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    text-transform: unset;
  }
`

export const LocationSubContainer = styled(Flex).attrs({})`
  position: relative;

  > svg {
    position: absolute;
    top: 0px;
    left: -40px;
  }

  input {
    margin: 0;
    color: ${themeGet('colors.text')};
    -webkit-text-fill-color: ${themeGet('colors.text')};
  }
`

export const AwardsTitle = styled(SubTitle).attrs({})`
  text-transform: none;
  margin: 0 !important;
  line-height: 1.4;
  font-size: 14px;
  font-weight: normal;
  color: ${themeGet('colors.text')};
`

export const QualificationsTitle = styled(SubTitle).attrs({})`
  text-transform: none;
  margin: 0 !important;
  line-height: 1.4;
  font-size: 14px;
  font-weight: normal;
  color: ${themeGet('colors.text')};
`

export const Dot = styled(Box)`
  background-color: ${({ bg }) => bg || themeGet('colors.boulder')};
  display: inline-block;
  border-radius: 50%;
  width: 4px;
  height: 4px;
`

export const Text = styled(SubTitle).attrs(({ top }) => ({
  mt: top ?? 2,
  fontSize: '14px',
}))`
  color: ${themeGet('colors.text')};
  text-transform: none;
  line-height: 1.4;
  font-weight: normal;
  word-wrap: break-word;

  p {
    margin-top: 8px;
  }
`

export const RatingText = styled(SubTitle).attrs(({ top }) => ({
  mt: top || 2,
  fontSize: 2,
}))`
  color: ${themeGet('colors.black')};
  text-transform: unset;
`

export const LinkText = styled(Box).attrs({
  as: 'a',
  target: '_blank',
  rel: 'noopener noreferrer',
  fontSize: [2, 2, 2],
  mt: [16, 16, 24],
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  font-family: Inter, sans-serif;
  text-decoration: underline;
  letter-spacing: 0.5px;

  &:active {
    opacity: 0.6;
  }
`

export const AlarmIcon = styled(Icon).attrs({
  glyph: alarmClockGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const BrowserIcon = styled(Icon).attrs({
  glyph: browserGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: 0px;
`

export const LikeIcon = styled(Icon).attrs({
  glyph: likeGlyph,
  size: 20,
})`
  fill: ${themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const MapIcon = styled(Icon).attrs({
  glyph: mapGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const ExternalLinkIcon = styled(Icon).attrs({
  glyph: externalLinkGlyph,
  size: 16,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
`

export const ClockIcon = styled(Icon).attrs({
  glyph: clockGlyph,
  size: 20,
})`
  stroke: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const PhoneIcon = styled(Icon).attrs(({ size }) => ({
  glyph: phoneGlyph,
  size,
}))`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -2px;
`

export const StarIcon = styled(Icon).attrs({
  glyph: starGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -4px;
`

export const TagIcon = styled(Icon).attrs({
  glyph: tagGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -4px;
`

export const FileIcon = styled(Icon).attrs({
  glyph: fileGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const GoodfoodhatIcon = styled(Icon).attrs({
  glyph: goodfoodhatGlyph,
  size: 20,
})`
  fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const MaskedText = styled(Text.withComponent(MaskedInput)).attrs({
  disabled: true,
  guide: false,
  fontSize: '14px',
})`
  background: none;
  border: 0;
  outline: none;
  margin-left: 0;
  -webkit-text-fill-color: ${themeGet('colors.text')}; /* safari */
  opacity: 1; /* safari iOS */
  padding: 0;
`

export const WebsiteText = styled(Box).attrs({
  fontSize: '14px',
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  font-family: Inter, sans-serif;
  text-decoration: underline;
  letter-spacing: 0.5px;
  word-break: break-all;

  &:active,
  &:hover {
    opacity: 0.7;
  }
`

export const ModalContent = styled(Flex).attrs({
  width: ['100%', '500px'],
})`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const ModalText = styled(BaseText).attrs({
  fontSize: [2, 2, 2],
  mt: 3,
})`
  color: ${themeGet('colors.boulder')};
  letter-spacing: 0.75px;
  width: 100%;
`

export const ModalButton = styled(Button).attrs({
  fontSize: [2, 2, 2],
  mt: [32, 45],
})`
  color: ${({ color }) => color || themeGet('colors.font.secondary')};
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  border-radius: 0;
  font-weight: 700;
  text-transform: uppercase;

  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 150px;

  &:hover:enabled {
    background-color: ${themeGet('colors.goldenrod')};
  }
`

export const MapHolder = styled(Flex).attrs({
  width: 1,
  mt: 4,
  justifyContent: 'center',
  alignItems: 'center',
  className: 'map-holder',
})`
  height: 300px;
  border-radius: ${themeGet('borderRadius.1')};

  background: url(${mapPlaceHolderImage}) no-repeat center center / cover
    content-box;

  @media screen and (min-width: 1200px) {
    flex: 1;
    height: 235px;
  }
`

const mapsButtonBorderRadiusCss = ({ borderradius }) => css`
  border-radius: ${borderradius
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const ViewMapsButton = styled(Flex).attrs({
  as: 'a',
  target: '_blank',
  rel: 'noopener noreferrer',
  mr: '5px',
  justifyContent: 'center',
  alignItems: 'center',
})`
  min-height: 30px;
  min-width: 90px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${({ color }) => color || mapToTheme('colors.white')};
  background-color: ${({ bgcolor }) => bgcolor || mapToTheme('buttons.bg')};
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }
  ${mapsButtonBorderRadiusCss};
`

export const LocationsHolder = styled(Flex)`
  flex-wrap: wrap;
  gap: 10px;
`

const activeLocationCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.white')};
    background-color: ${({ color }) => color || mapToTheme('buttons.bg')};
  `
export const LocationButton = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  min-height: 30px;
  min-width: 153px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: ${({ color }) => color || mapToTheme('buttons.bg')};
  border: 1px solid ${({ color }) => color || mapToTheme('buttons.bg')};
  border-radius: 5px;

  &:hover {
    color: ${themeGet('colors.white')};
    background-color: ${({ color }) => color || mapToTheme('buttons.bg')};
  }

  ${activeLocationCss};
`

export const DayRow = styled(Flex).attrs({
  fontSize: '14px',
})`
  width: 100%;
  line-height: 30px;
  color: ${themeGet('colors.boulder')};
  font-weight: ${({ bold }) => (bold ? '700' : 'normal')};
`

export const DayName = styled(Box).attrs({
  width: [100, 100, 100],
})`
  letter-spacing: 0.5px;
  color: ${themeGet('colors.boulder')};

  @media screen and (min-width: 1200px) {
    width: 80px;
  }
`

export const DayHours = styled(Flex).attrs({})`
  flex-wrap: nowrap;
  white-space: nowrap;
  flex: 1;

  @media screen and (min-width: 1200px) {
    margin-right: 20px;
  }
`

export const StatusDot = styled(Flex).attrs({
  mr: 3,
})`
  align-self: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ open }) =>
    open ? themeGet('colors.lapalma') : themeGet('colors.mercury')};
`

export const StyledSelect = styled(Select)`
  border: 1px solid ${themeGet('colors.concrete')};

  &:active,
  &:focus-within {
    border-color: ${({ color }) =>
      color || themeGet('colors.tealBlue')} !important;
  }

  .react-select {
    &__single-value {
      color: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
      font-weight: 300;
      margin-left: 32px;
    }
    &__value-container {
      padding-left: 16px;
    }
    &__option {
      color: #555;
    }
    &__option--is-selected {
      color: white;
      background-color: ${({ color }) =>
        color || themeGet('colors.tealBlue')} !important;
    }

    &__menu {
      background: white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      position: absolute;
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    .react-select {
      &__single-value {
        margin-left: 0;
      }
    }
  }
`

export const AboutText = styled(Box).attrs(({ top }) => ({
  mt: top || 2,
  fontSize: '14px',
}))`
  color: ${themeGet('colors.text')};
  text-transform: none;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;

  p {
    margin-top: 8px;
  }

  .about-read-more {
    color: ${({ linkcolor }) => linkcolor || mapToTheme('buttons.bg')};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const RatingStarsContainer = styled(Flex)`
  align-items: center;
`
export const ReviewAverage = styled(BaseText).attrs({
  fontSize: [18, 24],
})`
  font-weight: bold;
  color: ${themeGet('colors.goldenrod')};
`
export const ReviewTotal = styled(BaseText).attrs({
  fontSize: [18, 22],
})`
  cursor: pointer;
  font-weight: bold;
`

export const ContentTabsContainer = styled(Flex).attrs({
  my: 3,
  display: ['Flex', 'Flex', 'Flex', 'none'],
})`
  width: 100%;
`

export const ContentTab = styled(Flex).attrs({
  flex: 1,
  justifyContent: 'center',
  py: 2,
})`
  color: ${({ activecolor, active }) =>
    active ? activecolor : themeGet('colors.black')};

  text-align: center;
  letter-spacing: 0.7px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  border-bottom: 3px solid
    ${({ activecolor, active }) =>
      active ? activecolor : themeGet('colors.concrete')};

  &:hover {
    color: ${({ activecolor }) => activecolor};
    border-bottom: 3px solid ${({ activecolor }) => activecolor};
  }
`

export const NavigationHolder = styled(Flex)`
  align-items: center;
  gap: 8px;
`
export const AdjustedCol = styled(Box).attrs({
  px: 20,
  pl: 40,
  width: 1,
  className: 'address-holder',
})`
  flex: 1;
`
export const CloseLabel = styled(Flex).attrs({
  flex: 1,
})`
  @media screen and (min-width: 1200px) {
    margin-right: 20px;
  }
`

export const CustomFilterContainer = styled(Flex).attrs({
  flexDirection: 'column',
  pl: 40,
  flex: 1,
})`
  min-width: 200px;
`

export const AdditionalInfoContent = styled(Flex).attrs({
  flexDirection: 'row',
  flexWrap: 'wrap',
})`
  column-gap: 32px;
`
