import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import split from 'lodash/split'
import join from 'lodash/join'

import { useBaseTheme } from 'Hooks'

import { _ } from 'Services/I18n'
import { getDirectionsUrl } from 'Services/Utils/geo'

import {
  SubTitle,
  Text,
  NavigationHolder,
  ExternalLinkIcon,
  MapIcon,
  WebsiteText,
} from './styles'

const splitAddress = address => split(address, ', ')

const suburbStateCode = address => {
  const sa = splitAddress(address)
  sa.splice(0, 1)
  return join(sa, ', ')
}

const Address = ({ address, color, googlePlaceId, name }) => {
  const { secondaryColor } = useBaseTheme()

  const handleOpenNavigation = useCallback(e => {
    e.preventDefault()

    const url = getDirectionsUrl({
      id: googlePlaceId,
      address,
    })

    window.open(url)
  })

  return (
    <>
      <SubTitle mb="11px">
        <MapIcon color={color} />
        {_('common.address')}
      </SubTitle>
      <Text>{name}</Text>
      <Text top={0}>{splitAddress(address)[0]}</Text>
      <Text
        dangerouslySetInnerHTML={{
          __html: suburbStateCode(address),
        }}
        mb={11}
        top={0}
      />

      <NavigationHolder onClick={handleOpenNavigation}>
        <WebsiteText color={secondaryColor}>Directions</WebsiteText>
        <ExternalLinkIcon color={secondaryColor} />
      </NavigationHolder>
    </>
  )
}

Address.defaultProps = {
  address: '',
  color: '',
  name: '',
  googlePlaceId: '',
}

Address.propTypes = {
  address: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string,
  googlePlaceId: PropTypes.string,
  name: PropTypes.string,
}

export default Address
