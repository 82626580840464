import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useBaseTheme } from 'Hooks'

import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import { _ } from 'Services/I18n'

import { Container, Dash, StyledTitle, Text, AboutText } from './styles'

const About = ({ text, ...rest }) => {
  if (!text) {
    return null
  }

  const [useDescriptionEllipsis, setUseDescriptionEllipsis] = useState(true)
  const handleReadMore = () => {
    setUseDescriptionEllipsis(false)
  }

  const handleReflow = rleState => {
    const ele = document.getElementsByClassName('about-read-more')
    if (rleState.clamped) {
      ele[0].onclick = () => {
        handleReadMore()
      }
    }
  }

  const renderTruncatedDescription = useMemo(() => {
    return (
      <HTMLEllipsis
        basedOn="letters"
        ellipsisHTML={`... <span class="about-read-more">${_(
          'common.showMore',
        )}</span>`}
        maxLine="10"
        unsafeHTML={text}
        onReflow={handleReflow}
      />
    )
  }, ['text'])

  const { secondaryColor } = useBaseTheme()

  return (
    <Container {...rest}>
      <StyledTitle display={['none', 'none', 'none', 'block']}>
        {_('common.aboutUs')}
      </StyledTitle>
      <Dash display={['none', 'none', 'none', 'block']} />

      <AboutText linkcolor={secondaryColor} top={[0, 0, 0, 3]}>
        {useDescriptionEllipsis ? (
          renderTruncatedDescription
        ) : (
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </AboutText>
    </Container>
  )
}

About.defaultProps = {
  text: '',
}

About.propTypes = {
  text: PropTypes.string,
}

export default About
