import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'

import { useBaseTheme } from 'Hooks'
import useHydrated from 'Hooks/useHydrated'

import { ContentTabsContainer, ContentTab } from './styles'

const ContentTabs = ({ activeTab, tabs, onTabChange }) => {
  const { primaryColor } = useBaseTheme()
  const hydrated = useHydrated()

  return (
    hydrated && (
      <ContentTabsContainer>
        {map(tabs, ({ label, visible, id }) => {
          if (!visible) {
            return null
          }

          return (
            <ContentTab
              active={activeTab === id}
              activecolor={primaryColor}
              key={id}
              onClick={() => onTabChange(id)}
            >
              {label}
            </ContentTab>
          )
        })}
      </ContentTabsContainer>
    )
  )
}

ContentTabs.defaultProps = {
  activeTab: 0,
}

ContentTabs.propTypes = {
  activeTab: PropTypes.number,
  tabs: PropTypes.array.isRequired,
  onTabChange: PropTypes.func.isRequired,
}

export default ContentTabs
