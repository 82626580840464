import get from 'lodash/get'

export const merchantProperties = ({ merchant, index }) => {
  const openingHours =
    get(merchant, `locations[${index}].opening_hours`) ||
    get(merchant, 'opening_hours')

  const address = get(
    merchant,
    `locations[${index}].address`,
    get(merchant, `locations[${index}].full_address`, []),
  )

  const phone = get(merchant, `locations[${index}].phone_number`)
  const description = get(merchant, 'description')
  const multipleLocations = get(merchant, 'multiple_locations', false)
  const locations = get(merchant, 'locations', [])
  const name = get(merchant, `locations[${index}].name`)

  const googlePlaceId = get(
    merchant,
    `locations[${index}].google_map.place_id`,
    '',
  )

  const marker = {
    token: get(merchant, 'token'),
    location: get(merchant, `locations[${index}]`),
  }

  return {
    address,
    description,
    googlePlaceId,
    locations,
    name,
    marker,
    multipleLocations,
    openingHours,
    phone,
  }
}

export default {}
