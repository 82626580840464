import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { _ } from 'Services/I18n'

import { LocationsHolder, LocationButton, StyledSelect } from './styles'

const LocationTabs = ({
  locations,
  currentLocationIndex,
  primaryBackground,
  onLocationTab,
  onLocationSelect,
  ...rest
}) => {
  const handleLocation = index => () => {
    onLocationTab(index)
  }

  const handleChangeLocation = index => {
    onLocationSelect(index)
  }

  const renderMobileDropdown = () => {
    const options = map(locations, (location, index) => ({
      label: location.name,
      value: index,
    }))

    return (
      <StyledSelect
        color={primaryBackground}
        isMulti={false}
        isSearchable={false}
        options={options}
        placeholder={_('common.locations')}
        value={options[currentLocationIndex]}
        onChange={handleChangeLocation}
      />
    )
  }

  return (
    <>
      <LocationsHolder display={['none', 'none', 'flex']} mt={3} {...rest}>
        {map(locations, (location, index) => (
          <LocationButton
            active={currentLocationIndex === index ? 1 : 0}
            color={primaryBackground}
            key={index}
            onClick={handleLocation(index)}
          >
            {location.name}
          </LocationButton>
        ))}
      </LocationsHolder>
      <LocationsHolder display={['block', 'block', 'none']} mt={3} {...rest}>
        {renderMobileDropdown()}
      </LocationsHolder>
    </>
  )
}

LocationTabs.defaultProps = {
  primaryBackground: '',
  locations: [],
  currentLocationIndex: 0,
}

LocationTabs.propTypes = {
  currentLocationIndex: PropTypes.number,
  locations: PropTypes.array,
  primaryBackground: PropTypes.string,
  onLocationSelect: PropTypes.func.isRequired,
  onLocationTab: PropTypes.func.isRequired,
}

export default LocationTabs
