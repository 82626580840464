import React from 'react'
import PropTypes from 'prop-types'

import { _ } from 'Services/I18n'

import { Flex } from 'Components/UI'

import {
  Container,
  Dash,
  StyledTitle,
  Content,
  QualificationsTitle,
} from './styles'

const Qualifications = ({ qualifications, ...rest }) => {
  if (!qualifications) {
    return null
  }

  return (
    <Container {...rest}>
      <StyledTitle>{_('common.qualifications')}</StyledTitle>
      <Dash />
      <Content>
        <Flex mb={[0, 0, 4]} mt={3}>
          <QualificationsTitle
            dangerouslySetInnerHTML={{
              __html: qualifications,
            }}
          />
        </Flex>
      </Content>
    </Container>
  )
}

Qualifications.defaultProps = {
  qualifications: '',
}

Qualifications.propTypes = {
  qualifications: PropTypes.string,
}

export default Qualifications
