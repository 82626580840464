import React from 'react'
import PropTypes from 'prop-types'

import getServices from 'Services/Utils/getServices'

import { Flex } from 'Components/UI'
import { Pills } from 'Components/Blocks'

const CategoryPills = ({ services, ...rest }) => {
  if (!services?.length) {
    return null
  }

  const { categoryOptions } = getServices({ services })

  if (!categoryOptions?.length) {
    return null
  }

  return (
    <Flex {...rest}>
      <Pills big collapse pills={categoryOptions} />
    </Flex>
  )
}

CategoryPills.defaultProps = {
  services: null,
}

CategoryPills.propTypes = {
  services: PropTypes.array,
}

export default CategoryPills
