import React from 'react'
import PropTypes from 'prop-types'

import { _ } from 'Services/I18n'

import { Flex } from 'Components/UI'

import { Container, Dash, StyledTitle, Content, AwardsTitle } from './styles'

const Awards = ({ awards, ...rest }) => {
  if (!awards) {
    return null
  }

  return (
    <Container {...rest}>
      <StyledTitle>{_('common.awards')}</StyledTitle>
      <Dash />
      <Content>
        <Flex mb={[0, 0, 4]} mt={3}>
          <AwardsTitle
            color="boulder"
            dangerouslySetInnerHTML={{
              __html: awards,
            }}
          />
        </Flex>
      </Content>
    </Container>
  )
}

Awards.defaultProps = {
  awards: '',
}

Awards.propTypes = {
  awards: PropTypes.string,
}

export default Awards
