import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { _ } from 'Services/I18n'

import { getMapUrl } from 'Services/Utils/geo'
import { MARKETPLACE_SERVICE_TYPE } from 'Constants/ids'

import { Modal } from 'Components/UI'

import { merchantProperties } from 'Services/Utils/merchant'

import OpeningHours from './OpeningHours'
import Address from './Address'
import Telephone from './Telephone'
import LocationTabs from './LocationTabs'

import {
  Container,
  Dash,
  StyledTitle,
  Content,
  AdjustedCol,
  WebsiteText,
  BrowserIcon,
  ModalContent,
  ModalText,
  ModalButton,
  MapHolder,
  ViewMapsButton,
  LocationSubContainer,
  NavigationHolder,
  ExternalLinkIcon,
} from './styles'

class LocationWellness extends PureComponent {
  state = {
    showWebsitePopup: false,
    currentLocationIndex: 0,
  }

  handleWebsiteClick = () => {
    const { popupText } = this.props

    if (popupText) {
      this.setState(state => ({ showWebsitePopup: !state.showWebsitePopup }))
    } else {
      this.handleGoTo()
    }
  }

  handleGoTo = () => {
    const { website } = this.props
    const newWindow = window.open()
    newWindow.opener = null
    newWindow.location = website
    this.setState({ showWebsitePopup: false })
  }

  handleLocation = index => {
    this.setState({ currentLocationIndex: index })
  }

  handleChangeLocation = ({ value }) => {
    this.setState({ currentLocationIndex: value })
  }

  render() {
    const { showWebsitePopup, currentLocationIndex } = this.state
    const {
      color,
      hidePhone,
      marketplaceServiceType,
      merchant,
      website,
      popupText,
      primaryColor,
      primaryBackground,
      roundedButton,
      addressRef,
      ...rest
    } = this.props

    if (!merchant) return null

    const {
      openingHours,
      address,
      phone,
      multipleLocations,
      locations,
      name,
      googlePlaceId,
    } = merchantProperties({ merchant, index: currentLocationIndex })

    const showAddress =
      address && marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online

    const showPhone =
      !hidePhone &&
      phone &&
      marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online

    return (
      <Container {...rest}>
        <StyledTitle>{_('common.location')}</StyledTitle>
        <Dash />

        {(openingHours || showAddress) && multipleLocations && (
          <LocationTabs
            currentLocationIndex={currentLocationIndex}
            locations={locations}
            primaryBackground={primaryBackground}
            onLocationSelect={this.handleChangeLocation}
            onLocationTab={this.handleLocation}
          />
        )}

        <Content>
          {openingHours && (
            <AdjustedCol>
              <OpeningHours color={color} openingHours={openingHours} />
            </AdjustedCol>
          )}
          <AdjustedCol ref={addressRef}>
            {showAddress && (
              <>
                <Address
                  address={address}
                  color={color}
                  googlePlaceId={googlePlaceId}
                  name={name}
                />
              </>
            )}

            {showPhone && (
              <LocationSubContainer mt={showAddress ? 24 : [24, 24, 32]}>
                <Telephone color={color} phone={phone} />
              </LocationSubContainer>
            )}

            {website && (
              <LocationSubContainer mt={24}>
                <BrowserIcon color={color} />
                <NavigationHolder onClick={this.handleWebsiteClick}>
                  <WebsiteText color={color}>Website</WebsiteText>
                  <ExternalLinkIcon color={color} />
                </NavigationHolder>
              </LocationSubContainer>
            )}
          </AdjustedCol>

          {showAddress && (
            <MapHolder>
              <ViewMapsButton
                bgcolor={primaryBackground}
                borderradius={roundedButton}
                color={primaryColor}
                href={getMapUrl({
                  id: googlePlaceId,
                  address,
                })}
              >
                {_('common.viewOnMap')}
              </ViewMapsButton>
            </MapHolder>
          )}
        </Content>

        <Modal isOpen={showWebsitePopup} onCallback={this.handleWebsiteClick}>
          <ModalContent>
            <ModalText>{popupText}</ModalText>
            <ModalButton
              background={primaryBackground}
              color={primaryColor}
              onClick={this.handleGoTo}
            >
              {_('action.ok')}
            </ModalButton>
          </ModalContent>
        </Modal>
      </Container>
    )
  }
}

LocationWellness.defaultProps = {
  addressRef: null,
  color: '',
  hidePhone: false,
  marketplaceServiceType: 1,
  merchant: null,
  popupText: '',
  website: '',
  primaryColor: '',
  primaryBackground: '',
  roundedButton: false,
}

LocationWellness.propTypes = {
  addressRef: PropTypes.object,
  color: PropTypes.string,
  hidePhone: PropTypes.bool,
  marketplaceServiceType: PropTypes.number,
  merchant: PropTypes.object,
  popupText: PropTypes.string,
  primaryBackground: PropTypes.string,
  primaryColor: PropTypes.string,
  roundedButton: PropTypes.bool,
  website: PropTypes.string,
}

export default LocationWellness
