import React from 'react'
import PropTypes from 'prop-types'

import { get, map, split } from 'lodash'

import { _ } from 'Services/I18n'
import { useBaseTheme } from 'Hooks'
import Labels from 'Constants/labels'

import {
  Container,
  Dash,
  StyledTitle,
  Content,
  Col,
  SubTitle,
  Text,
  AlarmIcon,
} from './styles'

const Opening = ({ color, hours }) => {
  const { labels } = useBaseTheme()

  if (!hours) {
    return null
  }

  const text = split(hours, /<br\s?\/>/)
  const wrappedText = map(text, item => `<p>${item}</p>`).join('')

  return (
    <Container>
      <StyledTitle>
        {get(labels, Labels.TITLE_MERCHANT_TRADING_HOURS, 'Trading Hours')}
      </StyledTitle>
      <Dash />
      <Content>
        <Col>
          <SubTitle mb="11px">
            <AlarmIcon color={color} />
            {_('common.standardHours')}
          </SubTitle>
          <Text
            dangerouslySetInnerHTML={{
              __html: text.length === 0 ? hours : wrappedText,
            }}
          />
        </Col>
      </Content>
    </Container>
  )
}

Opening.defaultProps = {
  color: '',
  hours: '',
}

Opening.propTypes = {
  color: PropTypes.string,
  hours: PropTypes.string,
}

export default Opening
