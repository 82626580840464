import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { _ } from 'Services/I18n'

import { Flex, Box } from 'Components/UI'

import { Container, Dash, StyledTitle, Content, RatingText } from './styles'

const Ratings = ({ ratings }) => {
  const data = map(ratings, (rating, id) => ({ id, rating }))

  if (!ratings || data.length === 0) {
    return null
  }

  return (
    <Container>
      <StyledTitle>{_('common.ratings')}</StyledTitle>
      <Dash />
      <Content mt={20}>
        <Box>
          {map(data, ({ id, rating }) => (
            <Flex key={id}>
              <RatingText
                color="boulder"
                dangerouslySetInnerHTML={{
                  __html: rating,
                }}
                top={2}
              />
            </Flex>
          ))}
        </Box>
      </Content>
    </Container>
  )
}

Ratings.defaultProps = {}

Ratings.propTypes = {
  ratings: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Ratings
