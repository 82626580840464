import { useMemo } from 'react'

import get from 'lodash/get'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'

export default ({ services, selectedName }) => {
  return useMemo(() => {
    const groupedByService = reduce(
      services,
      (acc, service) => {
        const assignCategories = get(
          service,
          'assigned_categories.category_2',
          [],
        )

        forEach(assignCategories, ({ name, is_supplementary }) => {
          if (acc[name]) {
            acc[name].services.push(service)
            acc[name].is_supplementary = is_supplementary
          } else {
            acc[name] = {
              services: [],
              is_supplementary,
            }
            acc[name].services.push(service)
            acc[name].is_supplementary = is_supplementary
          }
        })

        return acc
      },
      {},
    )

    const categoryOptions = reduce(
      groupedByService,
      (acc, category, index) => {
        if (!get(category, 'is_supplementary')) {
          acc.push({
            label: index,
            value: category,
            labelIcon: true,
            labelIconActive: index === selectedName,
          })
        }

        return acc
      },
      [],
    )

    return {
      groupedByService,
      categoryOptions,
    }
  }, [services, selectedName])
}
